import Button from '@cmp/shared/Button/Button'
import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import { useEffect, useRef, useState } from 'react'
import SoundPlayer from 'react-sdk/SoundPlayer'
import Texts from 'react-sdk/Texts'
import { QCM } from 'react-sdk/activities/QCM'
import ActivityFeedback from '../ActivityFeedback/ActivityFeedback'
import ActivityTitle from '../ActivityTitle/ActivityTitle'
import classes from './Qcm.module.css'
import QcmChoices from './QcmChoices'
import QrlInput from './QrlInput'
import TimeBar from './TimeBar/TimeBar'

/**
 *
 * @param {object} props
 * @param {QCM} props.qcm
 * @returns
 */
const Qcm = ({qcm, onPoints, onFinished}) => {

  const ctnrRef = useRef(null)

  const [points, setPoints] = useState(0);

  const [step, setStep] = useState(0);
  const [qrlAnswer, setQrlAnswer] = useState("");


  useEffect(() => {
    qcm.reset()
    setStep(0)
    setPoints(0)
    setQrlAnswer("")
  }, [qcm]);

  useEffect(() => {
    onPoints(points)
  }, [points]);


  const validate = () => {
    // ici check les réponses
    qcm.localCorrect()

    SoundPlayer.playSound("QCM_validate")

    if(qcm.result.is_correct) {
      setPoints(10)
    }

    setTimeout(() => {
      ctnrRef.current.scrollTo({ top: ctnrRef.current.scrollHeight, behavior: "smooth" })
    }, 500)
  }


  const validateQRL = () => {
    SoundPlayer.playSound("QCM_validate")
    // ici check les réponses
    qcm.localCorrect(qrlAnswer)
    if(qcm.result.is_correct) {
      setPoints(10)
    }
    setTimeout(() => {
      ctnrRef.current.scrollTo({ top: ctnrRef.current.scrollHeight, behavior: "smooth" })
    }, 500)
  }



  const onContinue = () => {
    onFinished()
  }


  if(!qcm) return null

  return (
    <div className={clsx(classes.QCM, classes["step" + step ])} ref={ctnrRef}>

    {step >= 0 &&
    <ActivityTitle activity={qcm} />}

    {step === 0 &&
    <div  className={classes.qcm_valid_btn}>
      <Button onClick={() => setStep(1)} >{Texts.get("qcm-continuer")}</Button>
    </div>}


    {step >= 1 &&
    <>
      <TimeBar
      duration={qcm.duration || 30}
      onFinished={validate}
      pause={!!qcm.result}/>

      {qcm.Type === "question" &&
      <QcmChoices qcm={qcm}  />}


      {qcm.Type === "qrl" &&
      <QrlInput qcm={qcm} qrlAnswer={qrlAnswer} setQrlAnswer={setQrlAnswer} />}


      {qcm.result !== null &&
      <>
        <ActivityFeedback correct={qcm.result.is_correct} points={points} />

        <div className={classes.debrief}>
          {qcm.getDebrief()?.Text}
        </div>
        <div  className={classes.qcm_valid_btn}>
          <Button onClick={onContinue}>{Texts.get("qcm-continuer")}</Button>
        </div>
      </>}

      {qcm.Type === "question" && (qcm.result === null) &&
      <div  className={classes.qcm_valid_btn}>
        <Button disabled={qcm.selectedChoicesIds.length === 0} onClick={validate}>{Texts.get("qcm-valider")}</Button>
      </div>}

      {qcm.Type === "qrl" && (qcm.result === null) &&
      <div  className={classes.qcm_valid_btn}>
        <Button disabled={qrlAnswer.length === 0} onClick={validateQRL}>{Texts.get("qcm-valider")}</Button>
      </div>}
    </>}



    </div>
  )
}


export default observer(Qcm)