import Papa from 'papaparse'

import DataManager, {available} from "./haxelibs/DataManager"

const papaconfig = {
  delimiter: ";",	// auto-detect
  newline: "\n",	// auto-detect
  header: true,
  skipEmptyLines: true,
  dynamicTyping: true,
  transformHeader: (h) => h.trim()
}

class DataLoading {
  files = {}

  parseDataFile(relPath, content) {
    if(content.infos && content.infos.version === 2) {
      let coltypes = {}
      content.columns.forEach(c => coltypes[c.name] = c.type)

      let parsedData = content.data
      .filter(obj => Object.values(obj).some(val => val !== null))
      .map(obj => {
        for(let k in obj) {
          if(coltypes[k] === "numeric" && obj[k]) obj[k] = parseFloat(obj[k])
        }
        return obj
      })
      content.data = parsedData

      // ajout content dans le DM
      if (available())
        DataManager.createFile(relPath, content);

      return parsedData
    } else {
      // v1
      return content.data.map(line => {
        let lineObj = {}
        content.columns.forEach(({title, type}, index) => {
          let value = line[index]
          value = value === "" ? null : value
          if(type === "numeric" && value) {
            value = parseFloat(value)
          }
          lineObj[title] = value
        })
        return lineObj
      })
    }
  }


  getFile(url, name, type = null) {

    if(this.files[name] !== undefined) return Promise.resolve({data: this.files[name], name})

    // let extension = url.split('.').pop()

    let splitted = url.split('.')
    let extension = type != null? type : splitted.at(-1)


    var rand = new Date().getTime()
    return fetch(url + "?rand=" + rand)
    .then(res => {
      switch(extension) {
        case 'csv' :
        case 'txt' :
          return res.text()
        case 'data' :
        case 'char' :
        case 'json' :
        case 'activities' :
        case 'config' :
          return res.json()
        case 'pdf' :
          return res.arrayBuffer()
        default: return res.text()
      }
    })
    .then(data => {
      if(extension === "data") data = this.parseDataFile(url, data)
      if(extension === "csv") data = Papa.parse(data, papaconfig).data

      return {data, name}
    })
  }

  getAllFiles(genericFiles, root) {
    let promises = []

    for (let key in genericFiles) {
      let file = genericFiles[key]

      if(file.type) {
        promises.push( this.getFile(root + file.file, key, file.type) )
      }
      else {
        promises.push( this.getFile(root + genericFiles[key], key) )
      }
    }


    return Promise
    .all(promises)
    .then(files => {
      files.forEach(({name, data}) => {
        this.files[name] = data
      })

      return this.files
    })
  }

}


export default new DataLoading()
