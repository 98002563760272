import { DndContext, DragOverlay, pointerWithin, useDroppable, useSensor, useSensors } from "@dnd-kit/core";
import { snapCenterToCursor } from '@dnd-kit/modifiers';
import clsx from 'clsx';
import { useEffect, useRef, useState } from "react";
import { MouseSensor, TouchSensor, } from './DndSensorsOverwrite';
import classes from './DragDrop.module.css';


import Button from "@cmp/shared/Button/Button";
import { observer } from "mobx-react-lite";
import { FaChevronLeft, FaChevronRight, FaXmark } from "react-icons/fa6";
import ScaleManager from "react-sdk/ScaleManager";
import Texts from "react-sdk/Texts";
import Media from "../Media/Media";
import Draggable from "./Draggable/Draggable";
import Target from "./Target/Target";
import TimeBar from "../Qcm/TimeBar/TimeBar";
import ActivityTitle from "../ActivityTitle/ActivityTitle";
import { DragDrop as DD } from "react-sdk/activities/DragDrop";
import SoundPlayer from "react-sdk/SoundPlayer";
import ActivityFeedback from "../ActivityFeedback/ActivityFeedback";



const Bucket = ({children, with_media}) => {

  const {isOver, setNodeRef} = useDroppable({id: "bucket"});
  const [visibleIndex, setVisibleIndex] = useState(0);

  useEffect(() => {
    if(visibleIndex >= children.length || visibleIndex < 0) {
      setVisibleIndex(children.length - 1)
    }
  }, [children]);


  return (
    <div ref={setNodeRef} className={clsx(classes.bucket, isOver && classes.over,with_media && classes.with_media)}>

      {visibleIndex > 0 &&
      <Button onClick={() => setVisibleIndex(i => i-1)} className={classes.bucket_left} icon={<FaChevronLeft />} />}

      {children[visibleIndex]}

      {visibleIndex < children.length - 1 &&
      <Button onClick={() => setVisibleIndex(i => i+1)} className={classes.bucket_right} icon={<FaChevronRight />} />}

    </div>
  )
}

/**
 *
 * @param {{dd: DD}} props
 * @returns
 */
const DragDrop = ({dd, onPoints, onFinished}) => {

  useEffect(() => {
    if(!dd) return
    dd.reset()
    setStep(0)
    setActiveId(null)
    setPoints(0)
  }, [dd]);




  const ctnrRef = useRef(null)

  const [points, setPoints] = useState(0);
  const [info_popup, setInfo_popup] = useState(null);
  const [step, setStep] = useState(0); // 0 : jeu, 1: corrections, 2: bonnes réponses
  const [activeId, setActiveId] = useState(null);


  useEffect(() => {
    onPoints(points)
  }, [points]);


  const mouseSensor = useSensor(MouseSensor);
  const touchSensor = useSensor(TouchSensor);
  const sensors = useSensors(
    mouseSensor,
    touchSensor
  );

  if(!dd) return null

  function handleDragStart(data) {
    SoundPlayer.playSound("drapDrop_drag")
    setActiveId(data.active.id)
  }
  function handleDragEnd(data) {
    SoundPlayer.playSound("drapDrop_drop")
    if(!data.over) return // si on le lache en dehors d'un Droppable

    let proposition_id = data.active.id
    let target_id = data.over.id
    if(target_id === "bucket") {
      dd.place(proposition_id, null)
    }
    else {
      dd.place(proposition_id, target_id)

    }
  }

  function scaleModifier(args) {

    const {transform,} = args;

    return {
      ...transform,
      x: (transform.x / ScaleManager.scale) + (ScaleManager.marginPos === "left" ? ScaleManager.margin : 0),
      y: (transform.y / ScaleManager.scale) - (ScaleManager.marginPos === "top" ? ScaleManager.margin : 0)
    }
  }

  function validate() {
    setStep(2)
    dd.localCorrect()
    if(dd.result.is_correct) {
      setPoints(10)
    }

    setTimeout(() => {
      ctnrRef.current.scrollTo({ top: ctnrRef.current.scrollHeight, behavior: "smooth" })
    }, 50)


    let duration = window.CONFIG.drag_drop_correction_delay * 1000 || 2000

    setTimeout(() => {
      setStep(3)
      // onFinished()

    }, duration);
  }

  const nb_rows = Math.ceil(dd.targets.length / 2)
  return (
    <DndContext
    // autoScroll={false}
    sensors={sensors}
    collisionDetection={pointerWithin}
    onDragStart={handleDragStart}
    onDragEnd={handleDragEnd}>

      <div className={clsx(classes.DragDrop, classes["step" + step])} ref={ctnrRef}>

        {step >= 0 &&
        <ActivityTitle activity={dd} />}

        {step === 0 &&
        <div className={classes.dragdrop_valid}>
          <Button onClick={() => setStep(1)}>{Texts.get("dd-valider")}</Button>
        </div>}


        {step >=1 &&
        <>
          <TimeBar
          duration={dd.duration || 90}
          onFinished={validate}
          pause={step >= 2} />

          <Bucket with_media={dd.propositions.some(p => p.media)}>
            {step < 3 && dd.remainingPropositions
            .map(p =>
            <Draggable item={p} key={p.ID} />)}
          </Bucket>

          <div className={clsx(classes.targets, classes["rows_" + nb_rows])}>
            {dd.targets.map(t =>
            <Target
            target={t}
            key={t.ID}
            setInfo_popup={setInfo_popup}
            showCorrection={step >= 2}
            disabled={false}
            items={step === 3 ?  dd.getSortedPropositionsByTarget(t) : dd.getSortedPlacedPropositionsByTarget(t)}/>)}
          </div>

        </>
        }


        {dd.result &&
        <>
          <ActivityFeedback correct={dd.result.is_correct} points={points} />
        </>}

        {<div className={classes.dragdrop_valid}>
          {step === 1 && <Button disabled={!(dd.allPlaced)} onClick={validate}>{Texts.get("dd-valider")}</Button>}
          {step === 2 && <div className={classes.wait_correction}>{Texts.get("dd-wait-corrections")} </div>}
          {step === 3 && <Button onClick={onFinished}>Continuer</Button>}
        </div>}

      </div>



      {info_popup &&
      <div className={classes.info_popup}>
        <div>
          <Button onClick={() => setInfo_popup(null)} icon={<FaXmark />} className={classes.target_info_button} />
          <div dangerouslySetInnerHTML={{__html: Texts.parseMarkdown(info_popup, {noParagraph: true})}}></div>
        </div>
      </div>}

      <DragOverlay modifiers={[snapCenterToCursor, scaleModifier]} className={classes.drag_overlay_ctnr}>
        {activeId && <Draggable item={dd.getProposition(activeId)} dragged />}
      </DragOverlay>
    </DndContext>
  )
}

export default observer(DragDrop)