import React from "react";
import classes from './Qcm.module.css'
import Texts from "react-sdk/Texts";
import clsx from "clsx";
import { STATES } from "react-sdk/activities/ActivityPedaInstance";
import SoundPlayer from "react-sdk/SoundPlayer";
import Media from "../Media/Media";

const QcmChoices = ({qcm}) => {

  function handleChoiceClick(c) {
    if(!qcm.result) {
      SoundPlayer.playSound("QCM_answer")
      qcm.toggleChoice(c)
    }
  }

  return (
    <>
      <div className={classes.qcm_type}>
        {qcm.MultipleChoiceMode ?
          Texts.get("qcm-multiple-choices") :
          Texts.get("qcm-single-choice")}
      </div>

      <div className={clsx(classes.choices, qcm.choices.some(c => c.media) && classes.choices_grid)}>
        {qcm.choices.map((c, index) => {
        let _classes = [classes.choice_btn]

        if(c.selected) _classes.push(classes.selected)
        if(c.media) _classes.push(classes.with_media)



        if(qcm.result !== null) {
          if(c.state === STATES.CORRECT) _classes.push(classes.correct)
          if(c.state === STATES.WRONG) _classes.push(classes.wrong)
          if(c.state === STATES.MISSED) _classes.push(classes.missed)
        }

        return (
          <div
          key={index}
          className={clsx(_classes)}
          onClick={() => { handleChoiceClick(c) }}>
            {c.media && <div className={classes.qcm_choice_media}><Media path={c.media }/></div>}
            {c.Text && <div className={classes.choice_text} dangerouslySetInnerHTML={{__html: c.Text}}></div>}

            {qcm.result !== null &&
            <>
              {c.state === STATES.CORRECT && <div className={classes.choice_btn_icon}></div>}
              {c.state === STATES.WRONG && <div className={classes.choice_btn_icon}></div>}
              {c.state === STATES.MISSED && <div className={classes.choice_btn_icon}></div>}
            </>}
          </div>
        )
        })}
      </div>
    </>
  )
}

export default QcmChoices