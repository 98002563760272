import { makeAutoObservable, toJS } from "mobx"
import MeetingsApi from "./MeetingsApi"
import { Activity } from "./Activity"
import Notifications from "../../Notifications/Notifications"


/**
 * @typedef MeetingPlayer
 * @type {object}
 * @property {string} SessionID
 * @property {string} Pseudo
 * @property {number} Progress
 */



/**
 * Permet de modeliser un Meeting serveur, et d'observer certaines de ses propriétés afin de l'utiliser dans l'UI
 * Quand on obtient cet objet, on s'est déja connecté au meeting en tant que joueur/gamemaster
 */
export class Meeting {

  /** @type {Object.<string, MeetingPlayer>} */
  meetingPlayers = {}

  // TODO essayer de remplacer cela par des CustomEvents plutôt, cf ligne 629
  // ca permet de s'abonner de plusieurs endroits côté frontend
  onMeetingMessage = null
  onMeetingDataChange = null

  onUserListUpdate = null
  onUserConnect = null
  onUserDisconnect = null
  onUserChangePseudo = null

  /** @type {import("./MeetingsApi").VertxClientDef} */
  vertxClient = null

  sequencerMode = null

  _id = null
  MeetingName = null
  MeetingCode = null
  MeetingCodeDate = null

  /**
   *
   * @param {MeetingsApi} api
   * @param {{_id, MeetingName, MeetingCode, MeetingCodeDate, State}} meetingJson
   */
  constructor(vertxClient, meetingJson) {
    Object.assign(this, meetingJson)

    this.vertxClient = vertxClient

    /**
     * @type {Activity}
     */
    this.rootActivity = null

    this.OnMessageReceived = this.OnMessageReceived.bind(this)

    this.vertxClient.SIG_MeetingMessage.Add(this.OnMessageReceived)

    this.getPlayers()
    .catch(err => console.log("ERROR getPlayers", err))

    this.getSequencerMode()
    .catch(err => console.log("ERROR getSequencerMode", err))

    makeAutoObservable(this, {
      vertxClient: false
    })
  }

  getConfig() {
    return new Promise( (resolve, reject) => {
      this.vertxClient.UserMeetingGetConfig(this._id, (success, message, data) => {
        if(success) {
          try {
            let config = JSON.parse(data.DataArray[0])
            resolve(config)
          }
          catch(err) {
            reject(err)
          }
        }
        else {
          reject(message)
        }
      })
    })
  }

  disconnect() {

    return new Promise( (resolve, reject) => {
      this.vertxClient.UserMeetingDisconnect(this._id, (success, message, data) => {
        if(success) {
          this.vertxClient.SIG_MeetingMessage.Remove(this.OnMessageReceived)
          resolve(data)
        }
        else {
          reject(message)
        }

      })
    })
  }

  // renvoie les joueurs du meeting
  getPlayers() {
    return new Promise( (resolve, reject) => {
      this.vertxClient.UserMeetingGetConnectedPlayers(this._id, (success, msg, data) => {
        if(success) {
          let meetingUsers = {}
          if(data.DataArray) {
            data.DataArray.forEach(json => {
              // attention, dans la clé "_id", c'est en fait une SessionID
              let p = JSON.parse(json) // {_id: sessionID, Pseudo}
              meetingUsers[p._id] = {SessionID: p._id, Pseudo: p.Pseudo, isPlayer: false }

            })
          }
          this.meetingPlayers = meetingUsers
          resolve()
        }
        else {
          reject(msg)
        }
      })
    })
  }

  /**
   * permet de récupérer la liste des activities de ce meeting.
   * on ne l'appelle pas automatiquement, peut-être il n'y en a pas, ou on veut garder ça côté serveur
   *    *
   * @returns {Promise<Meeting>}
   */
  getActivities() {
    var cmd = {
      ActivityManager: {
        Command: 'GetConfig'
      }
    }
    return this._sendCommand(cmd)
    .then(data => {
      // console.log("GetConfig data", data)

      return JSON.parse(data.DataArray[0])

    })
    .then(data => {
      console.log("GetConfig data", data)
      this.rootActivity = new Activity(data, null)
      // console.log("this.rootActivity", toJS(this.rootActivity))
      return this
    })
    .catch(err => {
      console.log("GetConfig", err)
    })
  }

  clearActivities() {
    var cmd = {
      ActivityManager: {
        Command: 'Clear'
      }
    }
    return this._sendCommand(cmd)
  }

  getEnabledActivities() {
    var cmd = {
      ActivityManager: {
        Command: 'GetEnabled'
      }
    }
    return this._sendCommand(cmd)
    .then(data => JSON.parse(data.DataArray[0]))
  }

  getOpenedActivities() {
    var cmd = {
      ActivityManager: {
        Command: 'GetOpened'
      }
    }
    return this._sendCommand(cmd)
    .then(data => JSON.parse(data.DataArray[0]))
  }

  // ? renvoie les joueurs d'une activité ? a documenter
  getActivityPlayers() {
    var cmd = {
      ActivityManager: {
        Command: 'GetPlayers'
      }
    }
    return this._sendCommand(cmd)
    .then(data => JSON.parse(data.DataArray[0]))
  }

   /**
    * Permet de changer le mode de sequencement.
    * MANUAL : Il faut faire Next à la main après chaque activity
    * SEMI-AUTO, elles s'enchainent en automatique sauf si elle ont un flag SequencerAutoOpenNext à false
    * AUTO: elles s'enchainent en automatique quel que soit le flag
    * @param {"MANUAL"|"SEMI_AUTO"|"AUTO"} value
    * @returns
    */
  setSequencerMode(value) {
    let cmd = {
      ActivityManager: {
        Command: 'SetSequencerAutoMode',
        Value: value
      }
    }
    return this._sendCommand(cmd)
    .then(() => this.getSequencerMode())
  }

   /**
    * Permet de récupérer le mode de sequencement.
    * MANUAL : Il faut faire Next à la main après chaque activity
    * SEMI_AUTO, elles s'enchainent en automatique sauf si elle ont un flag SequencerAutoOpenNext à false
    * AUTO: elles s'enchainent en automatique quel que soit le flag
    * @returns
    */
  getSequencerMode() {
    let cmd = {
      ActivityManager: {
        Command: 'GetSequencerAutoMode'
      }
    }
    return this._sendCommand(cmd)
    .then(data => {
      return data.DataArray[0]
    })
    .then(val => {
      this.sequencerMode = val
      return val
    })

  }

  // CI DESSOUS, les fonctions liées à 1 activity.
  // TODO Peut-être les bouger dans Activity ???????????????
  enableActivity(activity_id, value) {
    let cmd = {
      ActivityManager: {
        Command: 'Enable',
        Activity: activity_id,
        Value: value
      }
    }
    return this._sendCommand(cmd)
  }

  openActivity(activity_id, value) {
    let cmd = {
      ActivityManager: {
        Command: 'Open',
        Activity: activity_id,
        Value: value,
      }
    }

    return this._sendCommand(cmd)
    .then(data => data.DataArray[0])
  }

  openNextActivity(current_activity_id, closeCurrent=false) {
    let cmd = {
      ActivityManager: {
        Command: 'OpenNext',
        Activity: current_activity_id,
        CloseCurrent: closeCurrent
      }
    }
    return this._sendCommand(cmd)
    .then(data => {
      if(data?.DataArray) {
        return data.DataArray[0]
      }
    })

  }

  moveActivity(activity_id, before, after ) {
    if(!before && !after) return Promise.reject("Either before or after should be set")
    if(before && after) return Promise.reject("You can't set before AND after")

    let cmd = {
      ActivityManager: {
        Command: 'Move',
        Activity: activity_id,
      }
    }
    if(after) cmd.ActivityManager.After = after
    if(before) cmd.ActivityManager.Before = before


    return this._sendCommand(cmd)
  }

  parentActivity(activity_id, parent) {
    let cmd = {
      ActivityManager: {
        Command: 'Parent',
        Activity: activity_id,
        Parent: parent
      }
    }
    return this._sendCommand(cmd)
  }

  enterActivity(activity_id) {
    var cmd = {
      Activity: {
        NetID: activity_id,
        Command: 'PlayerEnter'
      }
    }

    return this._sendCommand(cmd)
  }

  leaveActivity(activity_id) {
    var cmd = {
      Activity: {
        NetID: activity_id,
        Command: 'PlayerLeave'
      }
    }

    return this._sendCommand(cmd)
  }

  getFirstLeaf(activity_id) {
    var cmd = {
      Activity: {
        NetID: activity_id,
        Command: 'GetFirstLeaf'
      }
    }

    return this._sendCommand(cmd)
    .then(data => data.DataArray[0])
  }


  progressActivity(activity_id, progress) {
    let cmd = {
      Activity: {
        NetID : activity_id,
        Command: 'PlayerProgress',
        Progress : progress
      }
    }

    return this._sendCommand(cmd)
  }

  ////////// Commandes PlateauDeplacementActivity //////////
  sendChosenCell(activity_id, cell_id) {
    let cmd = {
      Activity: {
        NetID : activity_id,
        Command: "PlayerGoToCell",
        CellID: cell_id
      }
    }

    return this._sendCommand(cmd)
    .then(res => JSON.parse(res.DataArray[0]))
  }

  ///////////////////////////////////////////////////////////////////////////
  // permet de ne pas pourrir l'api avec des commandes custom pour les activities custom
  customActivityCommand(commandName, netId, parseReturn = false, additionalParameters = {} ) {
    let cmd = {
      Activity: {
        NetID : netId,
        Command: commandName,
        ...additionalParameters
      }
    }
    if(parseReturn) {
      return this._sendCommand(cmd)
      .then(res => {
        console.log("res", res)
        let val = res.DataArray[0]
        try {
          val = JSON.parse(val)
        } catch (error) {}

        return val
      })
    }

    return this._sendCommand(cmd)

  }

  _sendCommand(cmd) {
    return new Promise( (resolve, reject) => {
      this.vertxClient.UserMeetingCommand(this._id, "GameModule", JSON.stringify(cmd), (success, msg, data) => {
        // console.log("==================")
        // console.log("SEND COMMAND", cmd)
        // console.log("success", success)
        // console.log("msg", msg)
        // console.log("data", data)
        // console.log("==================")
        if(success) {
          resolve(data)
        } else {
          console.error("Error UserMeetingCommand : " + JSON.stringify(cmd) + " -> " + msg)
          reject(msg)
        }
      })
    })
  }

  /********* Meeting Custom Datas ****************************** */

  getMeetingData(dataId) {
    return new Promise( (resolve, reject) => {
      this.vertxClient.UserMeetingCustomDataGetItem(this._id, dataId, (success, msg, data) => {
        if(success) {
          resolve(data)
        } else {
          reject(msg)
        }
      })
    })
  }

  setMeetingData(dataId, data) {
    return new Promise( (resolve, reject) => {
      this.vertxClient.UserMeetingCustomDataSetItem(this._id, dataId, JSON.stringify(data), false, true, (success, msg, data) => {
          if(success) {
            resolve(data)
          } else {
            reject(msg)
          }
        })
      })
  }

  removeMeetingData(dataId) {
    return new Promise( (resolve, reject) => {
      this.vertxClient.UserMeetingCustomDataRemoveItem(this._id, dataId, false, true, resolve())
    })
  }


  UserMeetingGetConnectedPlayers() {
    return new Promise( (resolve, reject) => {
      this.vertxClient.UserMeetingGetConnectedPlayers(this._id, (success, msg, data) => {
          if(success) {
            resolve(data)
          } else {
            reject(msg)
          }
        })
      })
  }

  UserMeetingGetPlayerID(id) {
    return new Promise( (resolve, reject) => {
      this.vertxClient.UserMeetingGetConnectedPlayers(id, (success, msg, data) => {
          if(success) {
            resolve(data)
          } else {
            reject(msg)
          }
        })
      })
  }

  /********* UTILS ****************************** */

  OnMessageReceived(meeting_id, from_user_id, msg) {
    // console.log("OnMessageReceived", msg)
    if(meeting_id !== this._id) return

    if (from_user_id === undefined) from_user_id = "SERVER";

    try {
      let m = JSON.parse(msg)
      // console.log("m", m)

      if(m.Name === "Activity") {
         // message data
        let md = JSON.parse(m.Data)
        // console.log("md", md)

        /** @type {Activity} */
        let activity = this.findActivity(md.NetID)
        // console.log("activity", activity)
        if(!activity) return



        if(md.StatusEnabled !== undefined) {
          activity.setEnabled(md.StatusEnabled)
        }

        if(md.StatusOpened !== undefined) {
          activity.setOpened(md.StatusOpened)
        }

        if(md.TimeElapsed !== undefined) {
          // if(!activity.Children) console.log("from message => TimeElapsed", activity.Name, md.TimeElapsed)
          activity.setTimeElapsed(md.TimeElapsed)
        }

        if(md.ClosingIn !== undefined) {
          activity.setClosingIn(md.ClosingIn)
        }



        if(md.PlayersSessionID) {
          // un joueur entre dans l'activity (normalement déja présent dans les joueurs du meeting)
          // on l'ajoute dans les players de cette activity



          let playersObj = {}
          md.PlayersSessionID.forEach(s_id => {
            playersObj[s_id] = {
              _id: s_id,
              Pseudo: this.meetingPlayers[s_id].Pseudo,
              Progress: activity.Players[s_id]?.Progress || 0
            }

            // on reset aussi son progress à 0 dans les joueurs du meeting
            if(this.meetingPlayers[s_id]) {
              this.meetingPlayers[s_id].Progress = 0

              // flag le player comme étant un joueur pour les différencier des gamemasters connectés
              if(this.meetingPlayers[s_id].isPlayer === false) {
                this.meetingPlayers[s_id].isPlayer = true

                if(this.onUserListUpdate) this.onUserListUpdate(this.meetingPlayers)
              }
            }

          })
          activity.Players = playersObj



        }

        if(md.PlayerProgress) {

          // console.log("md.PlayerProgress", md.PlayerProgress)
          // {Progress:0.35,SessionID:"649da839da75004e9edaa7ac"}
          let {SessionID, Progress} = md.PlayerProgress

          // on remplace dans les players de l'activity
          let playerData = activity.Players[SessionID]
          if(playerData) {
            let newData = {...playerData, Progress}
            activity.Players = {...activity.Players, [SessionID]: newData}
          }

          // on remplace dans les players du meeting
          let m_player = this.meetingPlayers[SessionID]
          if(m_player) {
            let newData = {...m_player, Progress}
            this.meetingPlayers = {...this.meetingPlayers, [SessionID]: newData}
          }


        }

        if(md.StatusMoved) {
          this.getActivities()
          .then(data => {
            this.rootActivity = new Activity(data, null)
          })
        }

        if(md.activity_inserted) {
          md.activity_inserted.forEach(activity_msg => {
            try {

              let data = JSON.parse(activity_msg.activity)

              let activityToInsert
              if(activity_msg.parent) {
                let parent = this.findActivity(activity_msg.parent)
                activityToInsert = new Activity(data, parent)
              }
              else {
                activityToInsert = new Activity(data, null)
              }


              if(activity_msg.previous) {

                // ici il faut mettre la nouvelle activité APRES celle-ci
                let prev = this.findActivity(activity_msg.previous)
                console.log("=========== insert activity après", prev.Name)
                let i = prev.Parent.Children.findIndex(c => c.NetID === prev.NetID)
                prev.Parent.Children.splice(i + 1, 0, activityToInsert) // on insere après (i+1)
              }
              else if (activity_msg.next) {
                // ici il faut mettre la nouvelle activité AVANT celle-ci
                let next = this.findActivity(activity_msg.next)
                console.log("=========== insert activity avant", next.Name)
                let i = next.Parent.Children.findIndex(c => c.NetID === next.NetID)
                next.Parent.Children.splice(i, 0, activityToInsert) // on insere avant (i)
              }
              else if(activity_msg.parent) {
                // ici il faut push dans md.parent.Children
                let parent = this.findActivity(activity_msg.parent)
                console.log("=========== insert activity a la fin des children de ", parent.Name)
                if(!parent.Children) parent.Children = []
                parent.Children.push(activityToInsert)
              }

            }
            catch(err) {
              Notifications.error('Message activity_inserted error :' + err)
            }

          })

          // console.log("this.rootActivity", toJS(this.rootActivity))
        }


        const evt = new CustomEvent("activityMessage", {
          detail: {
            activity,
            message: md
          }
        })
        window.dispatchEvent(evt)

      }

      else if(m.Name === "UserStatus") {
        // message data
        let md = JSON.parse(m.Data)
        // console.log("UserStatus", toJS(md))

        if(md.Type === "Connection" && md.Connected) {
          // {
          //   Connected: true,
          //   Pseudo: "gildas",
          //   Type: "Connection",
          //   SessionID: "650073b56243bb2e856d09df
          // }

          //qqn se connecte
          this.meetingPlayers = {...this.meetingPlayers, [md.SessionID]: {SessionID: md.SessionID, Pseudo: md.Pseudo, isPlayer: false}}

          if(this.onUserConnect) this.onUserConnect(md.SessionID)
        }

        if(md.Type === "Connection" && !md.Connected) {
          if(this.onUserConnect) this.onUserDisconnect(md.SessionID)

          delete this.meetingPlayers[md.SessionID]

          // qqn se déconnecte
        }

        if(md.Type === "Pseudo") {
          let p = this.meetingPlayers[md.SessionID]
          if(p) {
            this.meetingPlayers = {...this.meetingPlayers, [md.SessionID]: {...p, Pseudo: md.Pseudo}}
            // this.meetingPlayers[md.SessionID] = {...p, Pseudo: md.Pseudo}

            if(this.onUserChangePseudo) onUserChangePseudo(md.SessionID, md.Pseudo)
          }
        }

        if(this.onUserListUpdate) this.onUserListUpdate(this.meetingPlayers)
      }
      else if(m.Name === "Context") {
        let md = JSON.parse(m.Data)




        const evt = new CustomEvent("contextMessage", {
          detail: {
            context: md.Value.RuntimeContext || md.Value
          }
        })
        window.dispatchEvent(evt)
      }
      else if(m.Name === "CustomData") {
        if(this.onMeetingDataChange) {
          let md = JSON.parse(m.Data)
          this.onMeetingDataChange(md.Key, md.Value)
        }
      }
      else {
        if(this.onMeetingMessage) this.onMeetingMessage(m)
      }
    }
    catch(err) {
      console.log("err", err)
      // console.log("original message",msg)
    }
  }

  /**
   *
   * @param {string|Function} func
   * @param {*} activity
   * @returns {Activity}
   */
  findActivity (func, activity = this.rootActivity) {

    // helper, si on passe une NetID, on crée la func a la volée
    if(typeof func === "string") {
      let act_id = func
      func = (a) => a.NetID === act_id
    }
    if(!activity) return null

    if(func(activity)) {
      return activity
    }

    if(activity.Children) {

      let index = 0
      let act = null

      while(act === null && index < activity.Children.length) {
        act = this.findActivity(func, activity.Children[index])
        index += 1
      }
      return act
    }
    return null
  }
  /**
   *
   * @param {activity} NetID
   * @returns {Activity}
   */
  getNextActivity(activity) {
    let flag = false
    let ordered =this.getOrderedNodes()

    for(let i in ordered) {
      let a = ordered[i]
      if(!a.Enabled) continue
      if(flag && !a.Children) {
        return a
      }
      if (a === activity) {
        flag = true
      }
    }

    return null
  }

  getOrderedNodes() {
    let nodes = []

    function recurse(currentNode) {
      if(!currentNode.Enabled) return
      nodes.push(currentNode)
      if(currentNode.Children) {
        currentNode.Children.forEach(child => {
          recurse(child);
        })
      }
    }

    recurse(this.rootActivity)
    return nodes
  }


}