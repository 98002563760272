/**
 * @typedef ActivityResult
 * @type {object}
 * @property {boolean} is_correct - la réponse est correcte
 * @property {boolean} joker_earned
 * @property {boolean} joker_used
 * @property {number} normalized_score // sert à rien
 * @property {Object} additionalInfos
 */

import { makeObservable, observable } from "mobx"
import Debrief from "./Debrief";

export const STATES = {
  CORRECT: "CORRECT",
  WRONG: "WRONG",
  MISSED: "MISSED",
}

class ActivityPedaInstance
{
  /** @type {ActivityResult} */
  result = null

  /** @type {Array<Debrief>} */
  debriefs = []


  addDebrief(json, isCorrect) {
    this.debriefs.push(new Debrief(json, isCorrect))
  }

  setResult(result) {
    this.result = result
  }

  /** @returns {Array} */
  getAnswer()  { console.log("getAnswer: OVERRIDE ME !"); }
  useJoker()   { console.log("useJoker: OVERRIDE ME !"); }


  // NOTE override ceci pour retourner un truc custom (null si on ne veut pas l'afficher, ou gérer l'affichage ailleurs dans le component Game par exemple)
  getDebrief() {
    // ici on renvoie le debrief qu'il faut
    if(this.debriefs.length === 0) return null

    if(this.debriefs.length === 1) return this.debriefs[0]

    if(this.debriefs.length === 2) {
      if(this.result.is_correct) {
        return this.debriefs.find(d => d.isCorrect)
      }
      else {
        return this.debriefs.find(d => !d.isCorrect)
      }
    }

    return null

  }

  get jokerAvailable() { return false } // TODO

  observe() {
    makeObservable(this, {
      result: observable
    })
  }
}


export default ActivityPedaInstance