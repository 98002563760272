import React, { useEffect, useMemo, useRef, useState } from 'react';

import classes from './PointerValider.module.css'

import clsx from 'clsx';
import Button from '@/components/shared/Button/Button';
import ScaleManager from 'react-sdk/ScaleManager';
import ActivityTitle from '../ActivityTitle/ActivityTitle';


import { observer } from 'mobx-react-lite';
import { PointerValider as PV } from 'react-sdk/activities/PointerValider';

import PV_correct from "./PV_correct.png"
import PV_missed from "./PV_missed.png"
import TimeBar from '../Qcm/TimeBar/TimeBar';
import Texts from 'react-sdk/Texts';
import ActivityFeedback from '../ActivityFeedback/ActivityFeedback';

/* TODO
* calcul des points

 + 5 * temps restant normalisé par zone trouvée
*/




/**
 *
 * @param {object} props
 * @param {PV} props.pv
 * @returns
 */
const PointerValider = ({pv, onPoints, onFinished}) => {
  const imgRef = useRef(null)
  const ctnrRef = useRef(null)
  const [imgSize, setImgSize] = useState(null);
  const [step, setStep] = useState(0);

  const timerRef = useRef(null);

  useEffect(() => {
    setStep(0)
    pv.reset()

    window.addEventListener("resize", handleImageSize)
    return () => {
      window.removeEventListener("resize", handleImageSize)
    }
  }, [pv]);

  useEffect(() => {
    if(step === 2) {
      setTimeout(() => {
        ctnrRef.current.scrollTo({ top: ctnrRef.current.scrollHeight, behavior: "smooth" })
      }, 500)
    }
  }, [step]);



  const points = useMemo(() => {
    if(!pv.result) return 0

    let norm = pv.result.normalized_score
    return Math.floor(norm * 40 / 10) * 10
  }, [pv.result])


  useEffect(() => {
    if(pv.result) {
      onPoints(points)
    }
  }, [pv.result]);

  function handleImageSize() {

    // NOTE on veut placer l'image à la taille max et au centre, et enregistrer les scales/offsets
    // NOTE je ne le fais pas en CSS avec object-fit car je ne peux pas récupérer ces infos ultérieurement, l'élément image fait 100% de son parent

    let i = imgRef.current
    let p = i.parentElement

    let imageRatio = i.naturalWidth / i.naturalHeight
    let parentRatio = p.offsetWidth / p.offsetHeight

    const fullwidth = imageRatio > parentRatio // l'image touche à gauche et droite

    if(fullwidth) {
      const w = p.offsetWidth
      const h = w / imageRatio
      const x = 0
      const y = (p.offsetHeight - h) / 2
      const scale = w / i.naturalWidth
      const parentHeight = p.offsetHeight

      setImgSize({w, h, x, y, scale, parentHeight})

    }
    else {
      const h = p.offsetHeight
      const w = h * imageRatio
      const x = (p.offsetWidth - w) / 2
      const y = 0
      const scale = w / i.naturalWidth

      const parentHeight = p.offsetHeight
      setImgSize({w, h, x, y, scale, parentHeight})
    }
  }

  function handleValidate() {
    const normRemainingTime = timerRef.current / (pv.duration || 30)
    pv.localCorrect(normRemainingTime)
    setStep(2)
  }


  function handleClick(e) {
    if(step === 2) return

    if(pv.markers.length >= pv.features.length) return

    // ici on pose un marker sur l'image
    var rect = e.target.getBoundingClientRect();
    let x = (e.clientX - rect.left) // valeur non scalée par le scalemanager
    x /= ScaleManager.scale

    let y = (e.clientY - rect.top)
    y /= ScaleManager.scale

    pv.addMarker(x, y, imgSize.scale )
  }

  function deleteMarker(e, marker) {
    e.stopPropagation()
    if(step === 2) return

    pv.deleteMarker(marker)
  }

  function handleReset() {
    pv.reset()
    setStep(0)
  }

  function handleFinish() {
    // NOTE fait ici et pas dans le useEffect car chrome semble se rappeler la scrollpos spécifique de cet état, je la reset donc avant de quit
    ctnrRef.current.scrollTo({ top: 0, behavior: "instant" })
    onFinished()
  }

  const imgstyle = useMemo(() => {
    console.log("processing imgstyle")
    const imgstyle = {}
    if(imgSize) {
      imgstyle.width = imgSize.w
      imgstyle.height = imgSize.h
      imgstyle.marginLeft = imgSize.x
      imgstyle.marginTop = imgSize.y,
      imgstyle["--scale"] = imgSize.scale
    }

    return imgstyle

  }, [imgSize])


  const imgCtnrStyle = {}
  if(imgSize) {
    imgCtnrStyle.height = imgSize.parentHeight
  }



  const disabled = pv.markers.length < pv.features.length

  return (

    <div className={clsx(classes.PointerValider, classes["step-" + step])} ref={ctnrRef} >
      {/* <div onClick={() => {setStep(0); setSelectedAreas([]) } }>reset</div> */}

      {step >= 0 &&
      <ActivityTitle activity={pv} showMedia={false} />}



      {step >= 1 &&
      <>
        <div className={classes.timebar_ctnr}>
          <TimeBar
          duration={pv.duration || 30}
          onFinished={handleValidate}
          pause={step !== 1}
          remainingTimeRef={timerRef} />
        </div>

        <div className={classes.image_ctnr} style={imgCtnrStyle}>
          <img
          ref={imgRef}
          onLoad={handleImageSize}
          src={`${window.CONFIG.root}/images/medias_pedago/${pv.media}`} style={imgstyle}
          />

          {step === 2 && imgSize &&
          <svg className={classes.zones} style={imgstyle} viewBox={`0 0 ${imgSize.w / imgSize.scale} ${imgSize.h / imgSize.scale}`} >
            {pv.features.map((f, index )=> {
              const centroid = f.centroidCoords
              return (
              <g className={clsx(step === 2 && (f.correct ? classes.correct : classes.wrong))} >
                <path
                key={index}
                d={f.svgPath()} />
                {step === 2 &&
                <>
                  {f.correct && <image  href={PV_correct} x={centroid[0]} y={centroid[1]} ></image>}
                  {!f.correct && <image href={PV_missed} x={centroid[0]} y={centroid[1]} ></image>}
                </>}

              </g>)
            })}

          </svg>}

          {imgSize &&
          <div
          onClick={handleClick}
          className={classes.markers_ctnr}
          style={imgstyle}>
            {pv.markers.map( m => {
              const left = m.x
              const top = m.y
              const style = {left,top}
              return (
                <div
                key={m.id + "-" + ScaleManager.scale}
                className={clsx(classes.marker, step === 2 ? m.correct ? classes.correct : classes.wrong : null)}
                style={style}
                onClick={(e) => deleteMarker(e, m.id)}
                ></div>
              )
            })}
          </div>}
        </div>
      </>}

      <div className={classes.bottom}>

        {pv.result &&
        <ActivityFeedback correct={pv.result.is_correct} points={points}>
          {pv.result.is_correct && Texts.get("feedback-correct")}
          {!pv.result.is_correct && Texts.get("pv-reponse-incomplete")}
        </ActivityFeedback>}

        {step === 2 && pv.Debrief &&
        <div className={classes.debrief} dangerouslySetInnerHTML={{__html: pv.Debrief}} />}

        <div className={classes.btn_ctnr}>
          {step === 0 && <Button onClick={() => setStep(1)} >{Texts.get("pv-continuer")}</Button>}

          {step === 1 &&
          <Button onClick={handleValidate} disabled={disabled}>
            {disabled && <>{pv.markers.length} / {pv.features.length}</>}
            {!disabled && Texts.get("pv-valider")}
          </Button>}

          {step === 2 && <Button onClick={handleFinish} >{Texts.get("pv-continuer")}</Button>}
        </div>
      </div>
    </div>
  );
};

export default observer(PointerValider);
