import { makeAutoObservable } from "mobx"
import { ACTIVITY_STATES } from "./MeetingsApi"

/**
 * Permet de modeliser une Activity serveur, et d'observer certaines de ses propriétés afin de l'utiliser dans l'UI
 */
export class Activity {
  Name
  NetID
  TimeElapsed = 0
  ClosingIn
  Duration
  Players = {}
  Enabled
  Opened
  Type
  DataRef
  InitData = {}
  SequencerAutoOpenNext=null

  constructor(jsonActivity, parentActivity=null) {
    // console.log("jsonActivity", jsonActivity)
    Object.assign(this, jsonActivity)

    this.setOpened(jsonActivity.Opened)

    /** @type {Activity}*/
    this.Parent = parentActivity

    /** @type {Array<Activity>}*/
    this.Children = this.Children && this.Children.map(child => new Activity(child, this))

    makeAutoObservable(this)
  }

  get Parent_id() {
    if(this.Parent) return this.Parent.NetID

  }

  get state() {
    if(!this.Enabled) return ACTIVITY_STATES.DISABLED
    if(!this.Opened) return ACTIVITY_STATES.ENABLED
    if(this.Opened) return ACTIVITY_STATES.OPENED
    return ACTIVITY_STATES.FINISHED
  }

  get AllPlayers() {
    // ici il faudrait choper les users de tous les enfants + les miens

    // on récup toutes les ids stock dans Players + children
    let all = {...this.Players}


    if(this.Children) {
      this.Children.forEach(child => {
        all = {...all, ...child.Players}
      })
    }

    return all
  }


  get AllChildrenIds() {
    // utility pour savoir si une activity en contient une autre...
    let ids = []

    if(this.Children) {
      this.Children.forEach(child => {

        ids = [...ids, ...child.AllChildrenIds, child.NetID]
      })
    }

    return ids
  }

  get StopsSequence() {
    return !(this.SequencerAutoOpenNext) || (this.Children && this.Children.at(-1).StopsSequence)
  }

  setEnabled(val) {
    this.Enabled = val

  }
  setOpened(val) {
    this.Opened = val
    if(!val) {
      clearTimeout(this.#timeout)
    }
  }

  #timeout
  setTimeElapsed(t) {
    // console.log(this.Name, "time : ", t)
    this.TimeElapsed = t
    clearTimeout(this.#timeout)
    this.TimeElapsed = this.Duration > 0 ? Math.min(t, this.Duration * 1000) : t
    if(this.Opened) {
      this.#timeout = setTimeout(_ => this.setTimeElapsed(this.TimeElapsed + 1000), 1000)
    }
  }

  #timeout2
  setClosingIn(t) {
    clearTimeout(this.#timeout2)
    this.ClosingIn = Math.max(t, 0)
    if(this.Opened) {
      this.#timeout2 = setTimeout(_ => this.setClosingIn(this.ClosingIn- 1000), 1000)
    }
  }

}